import React from "react";
import Header from "./header";
import Footer from "../components/common/footer";
import { graphql } from "gatsby";
import Termsanduse from "../components/common/termsanduse";
import { Helmet } from "react-helmet";
import { useTranslation, I18nextContext, Link } from "gatsby-plugin-react-i18next";



const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const Termsuse = () => {
  const { language } = React.useContext(I18nextContext)

  const buildCanonicalUrl = () =>
  language === "en"
    ? GATSBY_LANDING_PAGE_URL + "/terms-and-conditions/"
    : GATSBY_LANDING_PAGE_URL +`/${language}/terms-and-conditions/`

  const canoncalUrl = buildCanonicalUrl();
  const buildHrefUrl = () =>
  language === "en"
    ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
    : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
const hrefUrl = buildHrefUrl();

  const { t } = useTranslation()
  return (
    <div className="Layout">
      <Header />
      <Termsanduse />
      <Footer />
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
        
            `,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
            `,
          }}
        />
        <meta charSet="utf-8" />
        <title>{t("Logipulse Support - Logistics Support & Services")}</title>
        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
        <meta
          name="description"
          content={t("At Logipulse, our trained personnel offer optimal support and maintenance services for air, land, and ocean freight software, WPS software, 3PL solutions and more. Get hassle-free logistics support services from Logipulse.")}
        />
        <meta
          name="Keywords"
          content={t("logistics support and maintenance services")}        
        />
      </Helmet>
    </div>
  )
}

export default Termsuse

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
