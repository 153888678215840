import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/termsuse.scss";
import Photo from "../../components/Photo";
import "../../styles/accodian.scss";
import {Trans,I18nextContext,Link} from 'gatsby-plugin-react-i18next';




function Termsanduse(){
    const context = React.useContext(I18nextContext);
    // const homePageUrl = "https://www.logipulse.com/" + context.language;
        return (
            <div>   
      <div className="banner-feature no-supportmargin">
        <div className="container container-style">
          <div className="row">
            <div className="col-md-12">
              <div className="overlay-freight"></div>

              <div className="right-img"></div>
            </div>

            <div className="col-md-12">
              <div className="contents-imgs">
                <div className="pricing_details" lang={context.language}>
                  <Link to=""><Trans>HOME</Trans></Link> / <span className="view_features"><Link to ="/terms-and-conditions/"><Trans>Terms & Use</Trans></Link></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row support_position" lang={context.language}>
          <div class="col-sm-12">
            <div class="getfreedemo_contents_terms">
           
              <div className="support_heading_section_terms" >
                <div className="supportprofiles" lang={context.language}>
                 
                  <div className="terms_use_content">
                  <Trans>GENERAL TERMS & CONDITIONS</Trans>
                  </div>
                  <div className="terms_head"><Trans>Effective Date: December 2021</Trans></div>
                  <div className="terms_use_condition">
                      
                  <div className="terms_head"><b><Trans>USE OF SITE</Trans></b></div>
                  <div className="terms_contents"><Trans>The contents of this Site are the property of STELLOSYS or are licensed to STELLOSYS by a third party. Except as stated above, or unless otherwise expressly stated, no license is granted herein expressly, implied or otherwise under any patent, trademark, copyright or other intellectual property of STELLOSYS or any third party.</Trans></div>

                  <div className="terms_head"><b><Trans>ACCESS AND INTERFERENCE</Trans></b></div>
                  <div className="terms_contents"><Trans>You agree that you will not (a) use any device, software or routine that interferes with the proper working of the Site, (b) attempt to interfere with the proper working of the Site, or (c) take any action that imposes an unreasonable or disproportionately large load on the infrastructure that runs the Site.</Trans></div>

                  <div className="terms_head"><b><Trans>NON-DISCLOSURE</Trans></b></div>
                  <div className="terms_contents"><Trans>Unless otherwise agreed, each contracting party will treat any information (including documentation, samples etc.) disclosed to it by STELLOSYS as a trade secret, use such information solely for the purpose of the Contract, and refrain from disclosing it to any third parties.</Trans></div>

                  <div className="terms_head"><b><Trans>LIMITATION OF LIABILITY</Trans></b></div>
                  <div className="terms_contents"><Trans>Except for actions or claims arising from the gross negligence or intentional or wilful misconduct, liability to STELLOSYS shall be limited to the compensation fees based upon the contract.</Trans></div>

                  <div className="terms_head"><b><Trans>LINKS TO THIRD PARTY SITES</Trans></b></div>
                  <div className="terms_contents"><Trans>STELLOSYS does not review or control and is thus not responsible for any third-party sites or their content. Such links should not be assumed to constitute an endorsement or recommendation by STELLOSYS.</Trans><Trans>By clicking on any of these links, you are leaving the STELLOSYS Site and accessing these other sites at your own risk.</Trans></div>

                  <div className="terms_head"><b><Trans>COMMUNITY FORUMS</Trans></b></div>
                  <div className="terms_contents"><Trans>STELLOSYS may provide chat room, bulletin boards, blogs or other user forums Forums that allow third parties to post content and comments.</Trans> 
                  <Trans>STELLOSYS has the right, but not the obligation, to review or monitor third postings Submissions and to remove Submissions that contains material STELLOSYS deems offensive, abusive, defamatory, obscene or otherwise unacceptable.</Trans>
                  <Trans>However, STELLOSYS shall not be liable for claims arising from those Submissions whether arising under the laws of copyright, libel, privacy, obscenity or otherwise.</Trans></div>

                  <div className="terms_head"><b><Trans>GOVERNING LAW</Trans></b></div>
                  <div className="terms_contents"><Trans>These Terms shall be governed in all respects by the laws of the</Trans><b><Trans>The Cybersecurity Framework (NCFS)</Trans></b></div>

                  <div className="terms_head"><b><Trans>IMPORT</Trans>/<Trans>EXPORT</Trans></b></div>
                  <div className="terms_contents"><Trans>You may not access, download, use, or export information (including software, products or services) contained on this Site in violation of any applicable laws or regulations including, but not limited to the export laws of the European Union and United States.</Trans></div>

                  <div className="terms_head"><b><Trans>PRIVACY</Trans></b></div>
                  <div className="terms_contents"><Trans>For information on how STELLOSYS handles your personal information, see our Privacy Policy.</Trans></div>

                  <div className="terms_head"><b><Trans>INDEMNIFICATION</Trans></b></div>
                  <div className="terms_contents"><Trans>You agree to indemnify, defend and hold harmless, STELLOSYS, its subsidiaries, affiliates, joint ventures, business partners, licensors, employees, agents and third-party information providers from and against all losses, expenses, damages and costs, including attorney fees, resulting from any violation of these Terms including negligent or wrongful conduct by you or your use and access to the Site.</Trans></div>

                  </div>
                </div>
              </div>
            </div>      
          </div>
        </div>
      </div>

      <div class="end_divis_support">
        <div className="header_contents_sections text-right"></div>
        <div className="container">
          <div className="row">
            <div class="col-sm-12">
              <Photo
                src="Bottom Image-11.svg"
                class="innerimage"
                alt="logipulse-background-images"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
        )
   
}
export default Termsanduse
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;